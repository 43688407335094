async function asyncTest(){
    const promise = new Promise((resolve, reject) => {
        setTimeout(() => resolve('Ok !'), 2000)
    });

    let result = await promise; //Attend que la promesse soit résolue ou rejetée
    alert(result);
}


$(document).ready(function() {
    const $ctaHeader = $('.header--cta');
    if ($ctaHeader.length) {
        $ctaHeader.on('click', function (e) {
            preventDefault(e, 0);
            const $offset = $(this).data('href');
            scrollTo($offset);
        });
    }

    const $clientReference = $('.willowimpressionplan--cart--client-reference textarea');
    if ($clientReference.length) {
        $clientReference.on('focusout, blur', function() {
            const formData = $(this).val();
            $.ajax({
                url: 'modules/willowimpressionplan/assets/xhr/cart.php',
                type: 'post',
                data: 'client_reference=' + encodeURIComponent(formData),
                dataType: 'json',
            });
        });

        const $ctaCheckout = $('.willowimpressionplan--cart--detailed-totals--cta--link');
        $ctaCheckout.on('click', function () {
            const $href = $(this).attr('data-href');
            const formData = $clientReference.val();
            let $displayPopupCheck = true;


            // On vérifie si le panier contient un produit de la catégorie "Contenant"
            if ($('.willowimpressionplan--cart-comp--row.is-check').length) {
                $displayPopupCheck = false;
            }


            if ($displayPopupCheck) {
                // on affiche une modale
                $('#cart-modal-check').modal('show');
            }
            else {
                $.ajax({
                    url: 'modules/willowimpressionplan/assets/xhr/cart.php',
                    type: 'post',
                    data: 'client_reference=' + encodeURIComponent(formData),
                    dataType: 'json',
                    success: function ($response) {
                        if ($response.result == 'success') {
                            window.location = $href;
                        }
                    }
                });
            }

        })
    }

    const $newsEstimate = $('.home-news--item--estimate');
    if ($newsEstimate.length) {
        $('.home-news--item--img--hover--cta', $newsEstimate).on('click', function (e) {
            preventDefault(e, 0);
            const $offset = $(this).data('href');
            scrollTo($offset);
        });
    }

    const $homeNewsSlider = $('.home-news');
    if ($homeNewsSlider.length) {
        const $slider = $('.home-news--items', $homeNewsSlider);
        $slider.slick({
            arrows: true,
            centerMode: true,
            centerPadding: '0',
            cssEase: 'ease-in-out',
            dots: false,
            edgeFriction: 0,
            infinite: true,
            nextArrow: '<div class="slick-next"><i class="fas fa-chevron-right"></i></div>',
            prevArrow: '<div class="slick-prev"><i class="fas fa-chevron-left"></i></div>',
            slidesToScroll: 1,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    const $faqItem = $('.page--faq--content--item--link');
    if ($faqItem.length) {
        $faqItem.on('click', function (e) {
            const $this = $(this);
            $(this).find('.page--faq--content--item--content').fadeToggle(400, function () {
                $this.toggleClass('page--faq--content--item--active');

            });
        });
    }

    function scrollTo($offset) {
        $('html, body').animate({
            scrollTop: $($offset).offset().top,
        }, 500);
    }

    const $imgSvg = $('img.svg');
    if($imgSvg.length){
        $imgSvg.each(function(){
            const $img = $(this);
            const imgID = $img.attr('id');
            const imgClass = $img.attr('class');
            const imgURL = $img.attr('src');
            $.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                let $svg = $(data).find('svg');
                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }
                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');
                // Replace image with new SVG
                $img.replaceWith($svg);
            }, 'xml');
        });
    }

    $("#cart-summary-product-list .product-name").on("click", function () {
        const $icon = $(this).find("i.material-icons");
        if ($icon.text() === "expand_more") {
            $icon.text("expand_less");
        }
        else {
            $icon.text("expand_more");
        }
    })

    const $wipPushPriceTitleTxt = $('.willowimpressionplan--push-price--title-txt');
    if($wipPushPriceTitleTxt.length){
        $wipPushPriceTitleTxt.on('click', function (){
            if (window.matchMedia("(max-width: 1023px)").matches) {
                var $el = $(this).next();
                if($el.hasClass('active')){
                    $el.stop().slideUp(300);
                } else {
                    $el.stop().slideDown(300);
                }
                $el.toggleClass('active');
            }
        });
    }

    $('[data-toggle="tooltip"]').tooltip();
});

let WebFontConfig = {
    google: {families: ['Lato:400,700,900', 'Montserrat:400,500,600,700,800,900', 'Open+Sans:600']}
};
(function() {
    var wf = document.createElement('script');
    wf.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();